/* global gtag */
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Suspense, lazy } from 'react';
import Navigation from './components/Nav';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Marquee from "react-fast-marquee";
import Instructions from './components/Instructions';
import CheckoutRedirect from './components/CheckoutRedirect'; // Import the new component
import PageViewTracker from './components/PageViewTracker'; // Import the new component for page view tracking
import Banner from './components/Banner';
import ProductCards from './components/ProductCards';
import DTF from './components/DFT';
const Hero = lazy(() => import('./components/Hero'));
const MarqueeComponent = lazy(() => import('./components/MarqueeComponent'));
const TitleBreak = lazy(() => import('./components/TitleBreak'));
const Cards = lazy(() => import('./components/Cards'));
const About = lazy(() => import('./components/About'));
const Parallax = lazy(() => import('./components/Parallax'));

const InstaForm = lazy(() => import('./components/InstaForm'));
const Twitter = lazy(() => import('./components/Twitter'));
const ProductPage = lazy(() => import('./components/ProductPage'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const ShippingPolicy = lazy(() => import('./components/ShippingPolicy'));
const FAQ = lazy(() => import('./components/Faq'));
const Affiliate = lazy(() => import('./components/Affiliate'));
const Contact = lazy(() => import('./components/Contact'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const AboutInstagram = lazy(() => import('./components/AboutInstagram'));


function App() {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://sublisnap.com",
    "name": "SubliSnap!",
    "description": "SubliSnap is a revolutionary spray for sublimation printing on cotton, allowing vibrant and durable prints on cotton fabrics.",
    "publisher": {
      "@type": "Organization",
      "name": "SubliSnap",
      "logo": {
        "@type": "ImageObject",
        "url": "https://lh3.googleusercontent.com/a/ACg8ocLnEXZrIOrJOQjZ0hvykQqu0pWPHJsKN5G-lhJnPC4mYv0x6lA=s265-w265-h265"
      }
    },
    "sameAs": [
      "https://www.facebook.com/SubliSnap",
      "https://twitter.com/SubliSnap",
      "https://www.instagram.com/SubliSnap"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "971-266-3087",
      "contactType": "Customer Service",
      "email": "support@sublisnap.com"
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navigation />
        <PageViewTracker /> {/* Include the PageViewTracker component */}
        <Suspense fallback={<div className='loading'>Loading...</div>}>
          <Routes>
            <Route path="/" element={
              <>
                <Helmet>
                  <script type="application/ld+json">
                    {JSON.stringify(schema)}
                  </script>
                </Helmet>
                <Hero />
                <Marquee autoFill={true} style={{ backgroundColor: '#150764', padding: '1rem', overflow: 'hidden' }} >
                  <MarqueeComponent />
                </Marquee>
                <TitleBreak />
                <Cards />
                <Banner />
                <ProductCards />


                <About />
                <InstaForm />
                <Parallax />
                {/*}
                <Marquee>
                  <Twitter autoFill={true} style={{ backgroundColor: '#150764', padding: '1rem', overflow: 'hidden' }} />
                </Marquee>
                */}
              </>
            } />
            <Route path="/product-page/sublimation-spray" element={<ProductPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/affiliate-page" element={<Affiliate />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/social-sharing" element={<AboutInstagram />} />
            <Route path="/instructions" element={<Instructions />} />
            <Route path="/checkout" element={<CheckoutRedirect />} />
            <Route path="/dtf-transfers" element={<DTF />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
